import React, { Fragment } from "react"
import * as R from "ramda"
import { graphql } from "gatsby"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import Seo from "../components/seo"
import CollectionGrid from "../components/collectionGrid"
import Card from "../components/card"
import Button from "../components/button"
import LayoutController from "../components/layoutController"

const UpcomingExposPage = ({ data }) => {
  // UI texts
  const microCopyTexts = useMicroCopyFi
  const title = GetMicroCopy(microCopyTexts, "tulevatMessutOtsikko")
  const contentText = GetMicroCopy(microCopyTexts, "tulevatMessutTeksti")
  const content = R.path(["content"], data)
  let expos = R.path(["nodes"], content)

  // sort expos
  expos.forEach(item => {
    return (item.nr = parseInt(item.title.substring(item.title.length - 4)))
  })
  const sortByNameCaseInsensitive = R.sortBy(R.prop("nr"))
  expos = sortByNameCaseInsensitive(expos)

  //todo: add dynamic image

  return (
    <Fragment>
    <LayoutController language="fi"> 
      <Seo
        seoTitle={title}
        seoDesc={contentText}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image="https://images.ctfassets.net/ksssf3t869cm/5ChzSrkOZO8RMo68oNtFmw/1a551dff852dda066e5fe5042208933e/asuntomessut-naantali-2022.jpg?w=1200&h=800&q=60&fm=jpg"
      />
      <section className="max-w-5xl m-auto mt-8 mb-20">
        <header className="text-center mx-auto max-w-3xl mb-12 px-5">
          <h1>{title}</h1>
          <p>{contentText}</p>
        </header>
        <div className="px-5">
          <CollectionGrid tight>
            {expos.map(expo => (
              <Card
                key={expo.slug}
                title={expo.title}
                image={R.path(["gatsbyImageData"], expo.mainImage)}
                url={`${expo.slug}`}
                text={expo.contentTextShort}
                language="fi"
              />
            ))}
          </CollectionGrid>
        </div>
      </section>
    </LayoutController>
    </Fragment>
  )
}

export default UpcomingExposPage

export const pageQuery = graphql`
  query UpcomingExposPageQuery {
    content: allContentfulMessu(
      filter: {
        status: { eq: "tuleva" }
        slug: { ne: "jaerjestelmaetiedosto" },
        node_locale: { eq: "fi" }
      }
    ) {
      nodes {
        title
        slug
        contentTextShort
        mainImage {
          gatsbyImageData(layout: CONSTRAINED, width: 400)
        }
      }
    }
  }
`
